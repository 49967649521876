/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";






#alt_carousel_frontpage {
  max-height: 100vh;
}

#slider_1_background_img:hover,
#slider_2_background_img:hover,
#slider_3_background_img:hover,
#slider_4_background_img:hover,
#slider_5_background_img:hover {
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 0.3s linear;
}

.main_spin {
  margin: 0 auto;
  border: 0px solid;
  display: flex;
  align-items: center;
  justify-content: center;
}

.div_main_spin_logo {
  position: absolute;
  align-items: center;
  border-radius: 50px;
  margin-top: 40px;
  width: 300px;
  /* height: 200px; */
  /* width: 200px; */
}

.div_spin_button {
  text-align: center;
  align-items: center;
  justify-content: center;
}

.settingsCol {
  position: absolute;
  left: 0px;
  top: 0px;
  text-align: center;
  max-width: 310px;
  z-index: 2;
}

.close {
  left: -320px;
}

.gameCol {

}

.icons_toggle_sidemenu {
  position: absolute;
  top: 10px;
  left: 10px;
  color: #ffffff;
  font-size: 40px;
  text-align: left;
  float: left;
  z-index: 1002;
}

.textarea_names {
  text-align: left;
  width: 90%;
  border: 1px solid #cccccc;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  color: rgb(255, 255, 255);
  background: rgb(0 0 0 / 18%);
  min-height: 150px !important;
  max-height: 70vh;
}

.input_number {
  text-align: right;
  border: 1px solid #cccccc6b;
  width: 50px !important;
  margin-left: 10px;
  margin-right: 10px;
}

.div_build_wheel {
  margin-left: 0;
  margin-right: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.build_wheel_button {
  margin-left: auto;
  margin-right: auto;
}

.div_main_wheel {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100vw;
  z-index: 3;
}

.list_wheel_settings {
  background: rgb(255 255 255 / 0%) !important;
}

.item_wheel_settings .item-native {
  background: rgb(255 255 255 / 0%) !important;
  background-color: rgb(255 255 255 / 0%) !important;
}

.list_wheel_settings {
  background: rgb(255 255 255 / 0%) !important;
  background-color: rgb(255 255 255 / 0%) !important;
}

.item-inner {
  border: 0px !important;
}

.item-native {
  background: rgb(255 255 255 / 0%) !important;
  background-color: rgb(255 255 255 / 0%) !important;
}

.font_players {
  color: #ffffff;
  font-size: 18px;
}

.vertical-align-center {
  height: 100vh;
  align-content: center!important;
  align-items: center!important;
}

.div_winner {
  text-align: center;
  width: 100%;
  height: 120px;
  font-size: 76px;
  color: #ffffff;
  text-shadow: 5px 5px 10px #000000;
  padding: 0;
  margin: 0;
}

.font_winner {
  font-size: 76px;
  padding: 0;
  margin: 0;
}








/* Animations */

.img_one_bubble_slider {
  width: 100%;
}

.img_bubble_deco1 {
  width: 100%;
  z-index: 2000;
}

.img_bubble_deco2 {
  width: 70%;
}

.img_bubble_deco3 {
  width: 100%;
  z-index: 2000;
}

.img_bubble_deco4 {
  width: 100%;
  z-index: 2000;
}

.img_bubble_deco5 {
  width: 50%;
}

.img_bubble_deco6 {
  width: 30%;
}

.bubble_deco1 {
  position: absolute;
  left: 20%;
  margin: 0;
  padding: 0;
  animation: pulse 2s infinite linear;
}

.bubble_deco2 {
  position: absolute;
  left: 80%;
  margin: 0;
  padding: 0;
  transform: rotateZ(95deg);
  animation: pulse 2s infinite linear;
}

.bubble_deco3 {
  position: absolute;
  left: 30%;
  margin: 0;
  padding: 0;
  transform: rotate(145deg);
  animation: pulse 2s infinite linear;
}

.bubble_deco4 {
  position: absolute;
  left: 90%;
  margin: 0;
  padding: 0;
  animation: pulse 2s infinite linear;
}

.bubble_deco5 {
  position: absolute;
  left: 50%;
  margin: 0;
  padding: 0;
  transform: rotate(205deg);
  animation: pulse 2s infinite linear;
}

.bubble_deco6 {
  position: absolute;
  left: 15%;
  margin: 0;
  padding: 0;
  transform: rotate(325deg);
  animation: pulse 2s infinite linear;
}




/* Bubbles */

.bubbels{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  top: 0;
  left: 0;
}
.bubble{
  position: absolute;
  bottom: -100px;
  /*background: #f1f1f1;*/
  /*background: url('assets/img/background/one_bubble.png');*/
  border-radius: 50%;
  opacity: 1;
  animation: flying 10s infinite ease-in;
}
.bubble:nth-child(1){
  width: 40px;
  height: 40px;
  left: 10%;
  animation-duration: 8s;
  animation-delay: -1s;
}
.bubble:nth-child(2){
  width: 20px;
  height: 20px;
  left: 20%;
  animation-duration: 5s;
  animation-delay: -2s;
}
.bubble:nth-child(3){
  width: 50px;
  height: 50px;
  left: 35%;
  animation-duration: 10s;
  animation-delay: -3s;
}

.bubble:nth-child(4){
  width: 30px;
  height: 30px;
  left: 50%;
  animation-duration: 7s;
  animation-delay: -4s;
}

.bubble:nth-child(5){
  width: 35px;
  height: 35px;
  left: 55%;
  animation-duration: 6s;
  animation-delay: -5s;
}
.bubble:nth-child(6){
  width: 45px;
  height: 45px;
  left: 65%;
  animation-duration: 8s;
  animation-delay: -6s;
}
.bubble:nth-child(7){
  width: 25px;
  height: 25px;
  left: 75%;
  animation-duration: 7s;
  animation-delay: -7s;
}
.bubble:nth-child(8){
  width: 15px;
  height: 15px;
  left: 80%;
  animation-duration: 6s;
  animation-delay: -8s;
}

.bubble:nth-child(9){
  width: 15px;
  height: 15px;
  left: 70%;
  animation-duration: 9s;
  animation-delay: 0s;
}

.bubble:nth-child(10){
  width: 50px;
  height: 50px;
  left: 85%;
  animation-duration: 5s;
  animation-delay: 3s;
}

@keyframes flying {
  0%{
    bottom: -100px;
    transform: translateX(0);
  }
  50%{
    transform: translateX(100px);
  }
  100%{
    bottom: 1080px;
    transform: translateX(-200px);
  }
}




/* Fish Animation */

.fish1 {
  position: absolute;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  perspective: 400px;
  bottom: 35%;
  left: 15%;
  animation-name: fish1_animation;
  animation: fish1_animation 20s linear infinite;
}

.fish2 {
  position: absolute;
  width: 100px;
  height: 100px;
  bottom: 10%;
  left: 50%;
  margin: 0 auto;
  perspective: 200px;
  animation-name: fish2_animation;
  animation: fish2_animation 12s linear infinite;
}

.whale1 {
  position: absolute;
  width: 200px;
  height: 200px;
  margin: 0 auto;
  perspective: 400px;
  /* transform: scaleX(-1); */
  bottom: 15%;
  right: 5%;
  filter: grayscale(50%);
  opacity: 0.5;
  transform: scale(0.5);
  animation-name: whale1_animation;
  animation: whale1_animation 80s linear infinite;
}

.fish1 div {
  float: left;
}

.fish2 div {
  float: left;
}

.whale1_div {
  float: left;
}




@keyframes whale1_animation {
  0% {
    opacity: 0.0;
    transform: scale(0.0);
    filter: blur(0px);
  }
  50% {
    opacity: 0.5;
    transform: scale(1);
  }
  100% {
    opacity: 0.0;
    transform: scale(0.0);
    filter: blur(0px);
  }
}

@keyframes fish1_animation {
  0% {
    transform: translate(0,0) rotate(0) scale(1) scaleX(+1);
    -webkit-transform: translate(0,0) rotate(0) scale(1) scaleX(+1);
    filter: blur(0px);
  }
  20% {
    transform: translate(45%,50%) rotate(20deg) scale(0.8) scaleX(+1);
    -webkit-transform: translate(45%,50%) rotate(20deg) scale(0.8) scaleX(+1);
    filter: blur(0px);
  }
  40% {
    transform: translate(60%,60%) rotate(-5deg) scale(0.6) scaleX(+1);
    -webkit-transform: translate(60%,60%) rotate(-5deg) scale(0.6) scaleX(+1);
    filter: blur(0px);
  }
  60% {
    transform: translate(80%,70%) rotate(-10deg) scale(0.7) scaleX(+1);
    -webkit-transform: translate(80%,70%) rotate(-10deg) scale(0.7) scaleX(+1);
    filter: blur(0px);
  }
  61% {
    transform: translate(80%,70%) rotate(-10deg) scale(0.7) scaleX(-1);
    -webkit-transform: translate(80%,70%) rotate(-10deg) scale(0.7) scaleX(-1);
    filter: blur(0px);
  }
  80% {
    transform: translate(30%,20%) rotate(20deg) scale(0.9) scaleX(-1);
    -webkit-transform: translate(30%,20%) rotate(20deg) scale(0.9) scaleX(-1);
    filter: blur(0px);
  }
  99% {
    transform: translate(0,0) rotate(0) scale(1) scaleX(-1);;
    -webkit-transform: translate(0,0) rotate(0) scale(1) scaleX(-1);
    filter: blur(0px);
  }
}


@keyframes fish2_animation {
  0% {
    transform: translate(0,0) rotate(0) scale(1) scaleX(+1);
    -webkit-transform: translate(0,0) rotate(0) scale(1) scaleX(+1);
    filter: blur(0px);
  }
  20% {
    transform: translate(-15%,15%) rotate(5deg) scale(1.1) scaleX(+1);
    -webkit-transform: translate(-15%,15%) rotate(5deg) scale(1.1) scaleX(+1);
    filter: blur(0px);
    left: 70%;
  }
  40% {
    transform: translate(-30%,30%) rotate(10deg) scale(1.2) scaleX(+1);
    -webkit-transform: translate(-30%,30%) rotate(10deg) scale(1.2) scaleX(+1);
    filter: blur(0px);
    left: 80%;
  }
  41% {
    transform: translate(-30%,30%) rotate(10deg) scale(1.2) scaleX(-1);
    -webkit-transform: translate(-30%,30%) rotate(10deg) scale(1.2) scaleX(-1);
    filter: blur(0px);
    left: 80%;
  }
  60% {
    transform: translate(-20%,20%) rotate(7deg) scale(1.1) scaleX(-1);
    -webkit-transform: translate(-20%,20%) rotate(7deg) scale(1.1) scaleX(-1);
    filter: blur(0px);
    left: 65%;
  }
  80% {
    transform: translate(-12%,12%) rotate(5deg) scale(1.05) scaleX(-1);
    -webkit-transform: translate(-12%,12%) rotate(5deg) scale(1.05) scaleX(-1);
    filter: blur(0px);
    left: 55%;
  }
  99% {
    transform: translate(0,0) rotate(0) scale(1) scaleX(-1);
    -webkit-transform: translate(0,0) rotate(0) scale(1) scaleX(-1);
    filter: blur(0px);
    left: 50%;
  }
}

.div_dolphin_absolute {
  position: absolute;
  top: 150px;
  left: 70%;
}

.img_dolphin {
  max-width: 500px;
  max-height: 500px;
  animation: pulse 3s infinite linear;
}

.dolphin-front-wing-div {
  position: absolute;
  top: 20%;
  right: 5%;
  animation-name: dolphin_front_wing_animation;
  animation: dolphin_front_wing_animation 5s linear infinite;
}

.dolphin-back-wing-div {
  position: absolute;
  top: 20%;
  left: 20%;
  animation-name: dolphin_back_wing_animation;
  animation: dolphin_back_wing_animation 5s linear infinite;
}

@keyframes dolphin_front_wing_animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(5deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  80% {
    transform: rotate(-5deg);
  }
}

@keyframes dolphin_back_wing_animation {
  0% {
    transform: rotate(0deg);
  }
  20% {
    transform: rotate(-5deg);
  }
  40% {
    transform: rotate(0deg);
  }
  60% {
    transform: rotate(10deg);
  }
  80% {
    transform: rotate(5deg);
  }
}

/* end: Fish Animation */







/* Firewors Animation */

$fireworks_particles: 50;
$fireworks_width: 500;
$fireworks_height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $fireworks_particles {
  $box-shadow: $box-shadow,
    random($fireworks_width)-$fireworks_width /
      2 +
      px
      random($fireworks_height)-$fireworks_height /
      1.2 +
      px
      hsl(random(360), 100, 50);
  $box-shadow2: $box-shadow2, 0 0 #fff;
}
@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin animation-delay($settings) {
  -moz-animation-delay: $settings;
  -webkit-animation-delay: $settings;
  -o-animation-delay: $settings;
  -ms-animation-delay: $settings;
  animation-delay: $settings;
}

@mixin animation-duration($settings) {
  -moz-animation-duration: $settings;
  -webkit-animation-duration: $settings;
  -o-animation-duration: $settings;
  -ms-animation-duration: $settings;
  animation-duration: $settings;
}

@mixin animation($settings) {
  -moz-animation: $settings;
  -webkit-animation: $settings;
  -o-animation: $settings;
  -ms-animation: $settings;
  animation: $settings;
}

@mixin transform($settings) {
  transform: $settings;
  -moz-transform: $settings;
  -webkit-transform: $settings;
  -o-transform: $settings;
  -ms-transform: $settings;
}

body {
  margin: 0;
  padding: 0;
  background: #000;
  overflow: hidden;
}

.fireworks > .before,
.fireworks > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation(
    (
      1s bang ease-out infinite backwards,
      1s gravity ease-in infinite backwards,
      5s position linear infinite backwards
    )
  );
}

.fireworks > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}

@include keyframes(bang) {
  to {
    box-shadow: $box-shadow;
  }
}

@include keyframes(gravity) {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}

@include keyframes(position) {
  0%,
  19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%,
  39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%,
  59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%,
  79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%,
  99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}


/* end: Firewors Animation */









/* Countdown */

$totalEle: 3;
$duration:3s;
$speed: $duration / ($totalEle / 2);
$delay: $speed / 2;
$default-bg-color: black;
$complete-on: $duration + $delay + 0.5s;

@mixin cd_mixin() {
	@for $i from 1 through ($totalEle + 1) {

		&:nth-of-type(#{$i}) {
			color: adjust-hue(#ff0000, $i * 50% );

			@if( $i < ($totalEle + 1))  {
				animation:ani_1 $speed ($i * $delay) ease-in-out forwards;
			} @else {
				animation:ani_2 $speed ($i * $delay) ease-in-out forwards;
			}

			&:before {
				content: '#{ $totalEle + 1 - $i}';
			}
		}

	}
}


@keyframes ani_1 {
	0% {
		transform:translateY(20%);
		opacity: 0;
	}

	33.33%, 66.66% {
		transform:translateY(0%);
		opacity: 1;
	}

	100% {
		transform:translateY(-10%);
		opacity: 0;
	}
}

@keyframes ani_2 {
	0% {
		transform:translateY(20%);
		opacity: 0;
	}

	33.33%, 100% {
		transform:translateY(0%);
		opacity: 1;
	}
}

@keyframes ani_complete {
	0% {
		background-color: $default-bg-color;
	}
	100% {
		background-color: #fff;
	}
}


.animation-container {
	position: relative;
	width: 100%;
	height: 100%;
  /*display: none;*/

	span {
		position: absolute;
		left: 50%;
		top: 10%;
		color: #fff;
		z-index: 1;
    text-shadow: 0 0 20px #000000;

		margin-left: -25rem;
		margin-top: -17rem;

		width: 50rem;
		height: 30rem;
		overflow: hidden;
		text-align: center;
		opacity: 0;

		@include cd_mixin();


		&:before {
			font-size: 18rem;
			font-family: arial, verdana;
			line-height: 30rem;
			display: block;
			text-align: center;

		}
	}
}

/* end: Countdown */
